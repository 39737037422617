<!--
 * @Descripttion : 
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-19 17:09:12
 * @LastEditors  : min
 * @LastEditTime : 2021-01-28 21:27:06
-->
<template>
  <div class="ml_home">
    <a-config-provider :locale="locale">
      <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
          <div class="logo">
            <template v-if="collapsed">快卖车</template>
            <template v-else>快卖车二手车交易平台</template>
          </div>
          <a-menu theme="dark" mode="inline" :default-selected-keys="selectMenuIndex">
            <template v-for="(item, index) in menuList">
              <a-menu-item :key="index">
                <router-link :to="item.path">
                  <a-icon :type="item.icon" />
                  <span>{{ item.title }}</span>
                </router-link>
              </a-menu-item>
            </template>
          </a-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header style="background: #fff; padding: 0">
            <a-row>
              <a-col :span="18">
                <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)" />
              </a-col>
              <a-col :span="6">
                <div class="ml_dropdown">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                      {{ userName }}
                      <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item @click="editPassword">
                        修改密码
                      </a-menu-item>
                      <a-menu-item @click="loginOut">
                        注销
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content :style="{  minHeight: '280px',height:'calc(100vh - 65.25px - 30px)',overflow:'auto' }">
            <div class="ml_home_content">
              <router-view />
            </div>
          </a-layout-content>
          <a-layout-footer>Footer</a-layout-footer>
          <a-modal v-model="modalEditPassword" title="修改密码" centered @ok="submitEditPassword">
            <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
              <a-form-model-item has-feedback label="原密码" prop="password">
                <a-input-password v-model="ruleForm.password" type="password" autocomplete="off" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="新密码" prop="newPassword">
                <a-input-password v-model="ruleForm.newPassword" type="password" autocomplete="off" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="确认密码" prop="checkPassword">
                <a-input-password v-model="ruleForm.checkPassword" type="password" autocomplete="off" />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
        </a-layout>
      </a-layout>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { editPassword } from "../utils/api";
export default {
  name: "Home",
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("确认密码与新密码不同!"));
      } else {
        callback();
      }
    };

    return {
      collapsed: false,
      locale: zhCN,
      menuList: [
        {
          title: "报表",
          path: "/reportManage",
          icon: "bar-chart"
        },
        {
          title: "车辆管理",
          path: "/carManage",
          icon: "car"
        },
        {
          title: "求购管理",
          path: "/buyManage",
          icon: "shopping"
        },
        {
          title: "轮播管理",
          path: "/bannerManage",
          icon: "file-image"
        },
        // {
        //   title: "活动管理",
        //   path: "/activityManage",
        //   icon: "gift"
        // },
        {
          title: "用户管理",
          path: "/userManage",
          icon: "user"
        }
      ],
      selectMenuIndex: [0],
      modalEditPassword: false,
      ruleForm: {
        password: "",
        newPassword: "",
        checkPassword: ""
      },
      rules: {
        password: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        newPassword: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        checkPassword: [{ required: true, validator: validatePass2, trigger: 'change' }]
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 }
      },
      userName: this.$store.state.userInfo.userName
    };
  },
  created() {
    const that = this;
    that.menuList.forEach((element, index) => {
      if (element.path == this.$route.path) {
        that.selectMenuIndex = [];
        that.selectMenuIndex.push(index);
      }
    });
  },
  methods: {
    loginOut() {
      // 注销
      sessionStorage.removeItem("_user_token_");
      this.$router.push("/");
    },
    editPassword() {
      // 修改密码
      this.modalEditPassword = true;
    },
    submitEditPassword() {
      const that = this;
      that.$refs["ruleForm"].validate(valid => {
        if (valid) {
          editPassword({
            userId: that.$store.state.userInfo.userId,
            password: that.ruleForm.password,
            newpassword: that.ruleForm.newPassword
          }).then(() => {
            that.modalEditPassword = false;
            that.loginOut();
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ml_home {
  .ml_dropdown {
    text-align: right;
    padding-right: 20px;
  }
  .ant-layout-sider {
    height: 100vh;
  }
  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    overflow: hidden;
    font-weight: bold;
  }

  .ant-layout-footer {
    padding: 0;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #f8f8f8;
  }

  .ml_home_content {
    margin: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    // border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    background: #1890ff;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    // border-radius: 10px;
    background: #fff;
  }
}
</style>
